

const mapVilnius = document.querySelector("#shop-vilnius-map-container");
const mapKlaipeda = document.querySelector("#shop-klaipeda-map-container");
let newVilniusMap;
let newKlaipedaMap;

function initMap() {
    if (mapVilnius) {
        newVilniusMap = new google.maps.Map(mapVilnius, {
            zoom: 17,
            center: new google.maps.LatLng(54.734470, 25.254130),
            draggable: false,
            controls: true,
        });

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(54.734470, 25.254130),
            map: newVilniusMap,
            icon: "assets/img/horsemarket-icon.svg"
        });

    }
    if ( mapKlaipeda) {
        newKlaipedaMap = new google.maps.Map(mapKlaipeda, {
            zoom: 14,
            center: new google.maps.LatLng(55.811450, 21.111460),
            draggable: false,
        });

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(55.811450, 21.111460),
            map: newKlaipedaMap,
            icon: "assets/img/horsemarket-icon.svg",
            controls: true,
        });
    }

}

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})


document.addEventListener('DOMContentLoaded', function () {

})